import { api } from '@/utils';

const state = {
    all: [],
    loading: false,
    attachments: [],
};

const getters = {
    all: (state) => state.all,
    ticketById: (state) => (id) => state.all.find(ticket => ticket.id === id),
    ticketByLabelId: (state) => (label_id) => state.all.find(ticket => ticket.label_id === label_id),
};

const actions = {
    async getAttachmentByTicketId({commit}, ticketId) {
        const result = await api.getAttachmentByTicketId(ticketId);
        if (result.status === 'success') {
            commit('setAttachments', result.attachments);
            return result.attachments;
        }
    },

    async getTickets({commit}){
        commit('setLoading', true);
        try{
            const apiResponse = await api.getTickets();
            if(apiResponse.status === 'success') {
                commit('setTickets', apiResponse.tickets);
                return apiResponse.tickets
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('setLoading', false);
        }
    },
    async getSingleTicket(context, ticketId) {
        return await api.getSingleTicket(ticketId);
    },
    
    async update({dispatch}, {ticketId, update}) {
        const apiResponse = await api.updateTicket(ticketId, update);
        if (apiResponse.status === 'success')
            dispatch('getTickets');
        return apiResponse;
    }
};

const mutations = {
    setLoading (state, loading) {
        state.loading = loading;
    },
    setTickets (state, tickets) {
        state.all = tickets.tickets;
    },
    setAttachments (state, attachments) {
        state.attachments = attachments;
    }

}

export default {
    state,
    getters,
    actions,
    mutations
};
